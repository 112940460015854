import styled from 'styled-components/macro'

export const StyledBorderBox = styled.div`
  background-color: ${({ theme }) => theme.themeBg};
  border-radius: 16px;
  border: 1px solid #00000014;
  // border: 1px solid;
  // border-image-slice: 1;
  // border-image-source: linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%);
  box-shadow: 5px 4px 0px 0px #00000040;
`
