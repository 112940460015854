import { Box } from '@mui/material'
import { useAtomValue } from 'jotai/utils'
import styled from 'styled-components/macro'

import HeaderCell from './HeaderCell'
import { MarketSortMethod, sortMethodAtom } from './state'

const StyledSortBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 16px;
  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.lg}px`}) {
    justify-content: flex-start;
  }
`

const StyledSortItem = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  .sortItem {
    padding: 8px 16px;
  }
  .active {
    background-color: ${({ theme }) => theme.themeBg};
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #1ea7e4 0%, #01bbbe 100%);
    border-image-slice: 1;
    box-shadow: 5px 4px 0px 0px #00000040;
  }
`

export default function MarketSort() {
  const sortMethod = useAtomValue(sortMethodAtom)

  return (
    <StyledSortBox>
      <StyledSortItem>
        <div className={`sortItem ${sortMethod == MarketSortMethod.Marketcap && 'active'}`}>
          <HeaderCell category={MarketSortMethod.Marketcap} justify="flex-start" />
        </div>
      </StyledSortItem>
      <StyledSortItem>
        <div className={`sortItem ${sortMethod == MarketSortMethod.Participants && 'active'}`}>
          <HeaderCell category={MarketSortMethod.Participants} justify="flex-start" />
        </div>
      </StyledSortItem>
      <StyledSortItem>
        <div className={`sortItem ${sortMethod == MarketSortMethod.CreatedIn && 'active'}`}>
          <HeaderCell category={MarketSortMethod.CreatedIn} justify="flex-start" />
        </div>
      </StyledSortItem>
    </StyledSortBox>
  )
}
